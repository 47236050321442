import { Component } from 'react';
import { Mutation } from 'react-apollo';
import qs from 'qs';
import styled from 'styled-components';
import emailChangeConfirmationMutation from 'graphql/mutations/emailChangeConfirmation.graphql';
import MainTemplate from 'components/templates/MainTemplate';
import LoadingPage from 'components/pages/LoadingPage';
import Button from 'components/atoms/Button';
import ButtonLink from 'components/atoms/ButtonLink';
import Alert from 'components/atoms/Alert';
import DisableSSR from 'components/atoms/DisableSSR';

const StyledAlert = styled(Alert)`
  margin-bottom: 1em;
`;

class EmailChangeConfirmationPage extends Component {
  componentDidMount() {
    this.send();
  }

  send = () => {
    const { token } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    this.props.confirm({ variables: { token } });
  };

  renderErrorOrSuccess = () => {
    const { error } = this.props.result;
    if (
      error &&
      error.graphQLErrors &&
      error.graphQLErrors[0] &&
      error.graphQLErrors[0].extensions &&
      error.graphQLErrors[0].extensions.code &&
      error.graphQLErrors[0].extensions.code === 'BAD_USER_INPUT'
    ) {
      return (
        <StyledAlert type="error">
          Произошла ошибка. Неверный токен для смены E-mail. Вероятно вы воспользовались устаревшей
          ссылкой. Ссылка для подтверждения смены E-mail активна только в течении 24 часов.
        </StyledAlert>
      );
    }

    if (error) {
      return (
        <>
          <StyledAlert type="error">Произошла неизвестная ошибка.</StyledAlert>
          <Button neutral inline onClick={this.send}>
            Попробовать снова
          </Button>
        </>
      );
    }

    return (
      <>
        <StyledAlert type="success">E-mail изменён.</StyledAlert>
        <ButtonLink neutral inline to="/profile">
          Перейти в личный кабинет
        </ButtonLink>
      </>
    );
  };

  render() {
    const { loading, called } = this.props.result;

    if (loading || !called) {
      return <LoadingPage />;
    }

    return <MainTemplate>{this.renderErrorOrSuccess()}</MainTemplate>;
  }
}

export default (props) => (
  <DisableSSR>
    <Mutation mutation={emailChangeConfirmationMutation}>
      {(confirm, result) => (
        <EmailChangeConfirmationPage {...props} confirm={confirm} result={result} />
      )}
    </Mutation>
  </DisableSSR>
);
